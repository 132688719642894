export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Sundance',
  hostName: 'https://analytics.sundancedigital.com',
  environmentName: 'production',
  appName: 'Sundance Analytics',
  printUser: 'print@shiftdigital.com',
  printPassword: 'ShiftDigital#1',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: 'd8d59113-0f78-4643-8f97-3b78a99cb784'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#458691', '#C3982D', '#617C6A'],
  baseApiUri: 'https://sundance-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://sundance-analytics-api-prod.azurewebsites.net/token',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/sundance/favicon.ico',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false
};
