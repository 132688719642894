import {
    IDefaultTableColumnConfig,
    ISalesSummaryConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';

export class SalesSummaryConfig implements ISalesSummaryConfig {
    public reportTitle?: string;
    public metricDisplayModes: [];
    public graphConfig: IDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
    public trendTableMetricKeys?: string[] = [];
}
