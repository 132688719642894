import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Enums } from '../app/_shared/enums/enums';
import * as Models from './_shared/models/models-index';
import * as Services from './_shared/services/services-index';
import { ToasterConfig } from 'angular2-toaster';
import { forkJoin } from 'rxjs';
import { environment } from '../environments/environment';
import { map, filter, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from './_store/app-state.model';
import { selectLoggedInUser } from './_store/app.selectors';
import { AppActions } from './_store/action-types';
import { FilterActions } from './_shared/filter/store';
import { AppSelectors } from './_store/selector-types';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  toasterConfig: ToasterConfig = undefined;
  private routerSub: Subscription;
  private isUnsubscribe = true;
  printModeActive = false;
  private publicRoutes: string[] = ['/login', '/ssologin', '/ssologinV2', '/logout'];
  // pdf user
  private printUser: string = environment.printUser;
  private printPass: string = environment.printPassword;
  private baseUrl: string = environment.hostName;
  private user$ = this.store$.pipe(select(selectLoggedInUser));

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private store$: Store<AppState>,
    private appInsights: Services.AppInsightsService,
    private authService: Services.AuthenticationService,
    private configService: Services.ConfigurationService,
    private router: Router,
    private titleService: Title,
    private filterService: Services.FilterService,
    private cache: Services.LocalCacheService,

    private printService: Services.PrintService,
    private orgService: Services.OrgLookupService
  ) {
    // console.log(this.router.config);

    this.appInsights.setHostDomain(this.configService.hostInformation.hostName);
    this.toasterConfig = this.configService.toasterConfig;
  }

  verifyLoggedIn$ = this.user$.pipe(
    map(user => {
      if (user) {
        const exp = user.expireDate;
        const oneDay = (23 * 60 * 60 + 1) * 1000;
        const curExp = exp.getTime();
        const newExp = (new Date()).getTime();
        const newDate = new Date();
        const diff = exp.getTime() - (new Date()).getTime();
        if (diff > oneDay) {
          return true;
        }
      }
      return false;
    })
  );

  ngOnInit() {
    // this.store$.dispatch(AppActions.loadConfig());
    this.titleService.setTitle(environment.appName);
    this.favIcon.href = environment.favIconPath;


    const isSsoV2 = location.pathname.includes('ssoLoginV2') ? location.search.lastIndexOf('?authData', 0) === 0 : false;
    const isSso = location.search.lastIndexOf('?authData', 0) === 0;

    if(isSsoV2) {
      this.router.navigateByUrl('/ssoLoginV2' + location.search);
    } else if (isSso) {
      this.router.navigateByUrl('/ssoLogin' + location.search);
    }

    // Setup redirect route when navigating to /
    this.store$.select(AppSelectors.selectLoggedInUserAndConfig).pipe(
      tap(([_, config]) => {
        if (!config || !config.dealerLookups || !config.dealerLookups.length) {
          console.log("config load failed");
          this.store$.dispatch(AppActions.loadConfig());
        }
        // this.store$.dispatch(AppActions.loadLocale());
      }),
      filter(([user, config]) => !!user && user.isAuthenticated && config.dealerLookups.length > 0)
    ).subscribe(([user, config]) => {
      this.orgService.setOrgLookups(config.orgLookups, user);
      // this.store$.dispatch(FilterActions.loadDefaultFilter());

      if (user) {
        const printModeModel = this.cache.retrieveCache<Models.IPrintModeModel>(Enums.cacheKeys.session);
        if (printModeModel) {
          this.printService.togglePrintMode(true);
        } else {
          let redirectRoute = '';
          redirectRoute = '/leads/overview';
          const baseLayoutRoute = this.router.config.find(c => c.path === '');
          const defaultRoute = baseLayoutRoute.children?.find(c => c.path === '');

          if (defaultRoute) {
            defaultRoute.redirectTo = redirectRoute;
          }
        }
      } else {
        this.router.navigateByUrl('/login');
      }
    });

    this.isPrintMode().then(printMode => {    // We want to always scroll to the top of the page whenever a user navigates
      if (!printMode) {
        this.routerSub = this.router.events.subscribe((evt) => {
          if ((evt instanceof NavigationStart)) {
            // We don't want to call on the filterService when we're not authenticated because we don't have a hydrated authData object and the app pukes
            if (!this.publicRoutes.includes(evt.url.toLowerCase())) {
              // this.filterService.resetCurrentFilterModelToDefault();
            }
          }
          if (!(evt instanceof NavigationEnd)) {
            return;
          }
          window.scrollTo(0, 0);
        });
      } else {
        this.printModeActive = true;
        const printModeModel: Models.IPrintModeModel = {
          isPrintMode: true
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

  private requireLogin(): void {
    this.router.navigateByUrl('/login');
  }

  // this is new for print mode -- this is old now and need to clean it up
  private isPrintMode(): Promise<boolean> {
    const printGuid = this.getParameterByName('print');
    if (printGuid) {
      return new Promise<boolean>((resolve, reject) => {
        forkJoin( // Print user login
          this.authService.login(this.printUser, this.printPass)
        ).subscribe(([loginResponse]) => {
          if (loginResponse) {
            forkJoin(
              this.printService.getByGuid(printGuid)
            ).subscribe(([printResponse]) => {
              if (printResponse) {
                // this.filterService.setPrintFilterModel(printResponse.filterModel);
                this.store$.dispatch(AppActions.updateLocale({ locale: printResponse?.locale ?? 'en' }));
                this.store$.dispatch(FilterActions.updatePrintingFilters({ reportName: printResponse.reportName, filters: printResponse.filters }));
                // set print mode to true
                this.printService.togglePrintMode(true);
                this.router.navigateByUrl(printResponse.path);
                resolve(true);
              } else {
                resolve(false);
              }
            });
          } else {
            resolve(false);
          }
        });
      });
    }
    return Promise.resolve(false);
  }

  private getParameterByName(name: string): string {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

    let data = location.search;
    if (!data) {
      const idx = location.href.indexOf('?');
      if (idx !== -1) {
        data = location.href.substring(idx);
      }
    }
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(data);
    const rv = results === null ? null : results[1].replace(/\+/g, ' ');
    return rv;
  }
}
