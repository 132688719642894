import { Component, Inject, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import * as APIService from '../../_shared/services/api/api-index';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as Models from '../../_shared/models/models-index';
import { MinutesToHoursPipe } from '../../_shared/pipes/minutesToHours.pipe';
import { ConfigurationService, SpinnerService } from '../../_shared/services/services-index';
import { Subscription, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Component({
  templateUrl: './leadDetailsModal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LeadDetailsModalComponent implements OnDestroy, OnInit {
  dealerLookups: Models.IDealerLookup[];
  reportConfig: Models.ILeadTransactionsConfig;
  lead$: Observable<Models.LeadTransactionTableMetricReportRecord>;
  leadDispositions$: Observable<Models.LeadTransactionDispositionReportRecord[]>;
  subscription = new Subscription();
  excludeFromTemplate: string[] = [];

  requestModel: Models.ReportRequestModel = {
    orderBy: '',
    orderAscending: false,
    reportType: '',
    filters: {
      startDate: new Date(null),
      endDate: new Date(),
      leadId: null,
      orgLookupTypeId: 1,
    }
  };

  constructor(public dialogRef: MatDialogRef<LeadDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private minutesToHoursPipe: MinutesToHoursPipe,
    private reportService: APIService.LeadTransactionService,
    private configService: ConfigurationService,
    private spinner: SpinnerService
  ) {

    this.reportConfig = this.configService.reports.leadTransactionsConfig;
    this.requestModel.filters.leadId = data.lead.leadId;
    this.requestModel.filters.roleLevel = data.roleLevel;
    this.excludeFromTemplate = data.excludeFromTemplate;
  }

  ngOnInit(): void {
    this.lead$ = this.getLeadDetails().pipe(tap(() => this.spinner.hide()));
    this.leadDispositions$  = this.getLeadDispositions().pipe(tap(() => this.spinner.hide()));
  }

  ngOnDestroy(): void {

  }

  getLeadDetails(): Observable<Models.LeadTransactionTableMetricReportRecord> {
    this.spinner.show();
    const reportRequestModel: Models.ReportRequestModel = JSON.parse(JSON.stringify(this.requestModel));
    return this.reportService.getLeadTransactionByLeadId(reportRequestModel, 'leadtransactionbyleadid').pipe(
      map(response => response.results.find(result => result.leadId === this.requestModel.filters.leadId))
    );
  }

  getLeadDispositions(): Observable<Models.LeadTransactionDispositionReportRecord[]> {
    this.spinner.show();
    const reportRequestModel: Models.ReportRequestModel = JSON.parse(JSON.stringify(this.requestModel));
    return  this.reportService.getTransactionDispositions(reportRequestModel, 'leadtransactiondispositions').pipe(
      map(response => response.results)
    );
  }

  convertUtcToCst(date: Date): Date {
    let dateCopy = new Date(date);
    dateCopy = new Date(dateCopy.setHours(dateCopy.getHours() - 5));
    return dateCopy;
  }

  timeToRespond(minutes: number): string {
    const result = this.minutesToHoursPipe.transform(minutes);
    return result !== 'N/A' ? result : '0m';
  }

  sale(lead: Models.LeadTransactionTableMetricReportRecord): string {
    return lead.saleDate ? 'Y' : 'N';
  }

  sameDealer(lead: Models.LeadTransactionTableMetricReportRecord): string {
    if (!(lead.saleDate)) { // no sale
      return '';
    }
    return lead.dealerCode === lead.saleDealerCode ? 'Y' : 'N';
  }

  closeModal() {
    this.dialogRef.close();
  }
}
