import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';
import {
    IDigAdOverviewBillboardConfig,
    IDigAdOverviewConfig,
    IDefaultTableColumnConfig,
    IDigAdOverviewKpiConfig,
    KpiSheetOptions,
    ICardHeaderConfig,
    IDigAdOverviewChannelCardConfig,
    IDigAdOverviewTrafficTypeCardConfig} from '../../../../_shared/models/models-index';

export class DigAdOverviewConfig implements IDigAdOverviewConfig {
    public channelCardConfig: IDigAdOverviewChannelCardConfig = null;
    public trafficTypeCardConfig: IDigAdOverviewTrafficTypeCardConfig = null;

    public reportTitle: string = '';
    public dealerLabel: string = 'Dealer';
    public channelColors: string[] = [];
    public chartSeriesColors: string[] = [];
    public trafficTypeColors: string[] = [];
    public billboards: IDigAdOverviewBillboardConfig[] = [];
    public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [];
    public dealerBenchmarkLookbackColumns?: IDefaultTableColumnConfig[] = [];
    public kpiCardConfig: IDigAdOverviewKpiConfig = null;
    public kpiSheetOptions: Record<KpiSheetOptions, KpiSheetColumn[]> = null;

    public providerPerformanceCardHeader: ICardHeaderConfig = null;
    public trafficTypeCardHeader: ICardHeaderConfig = null;
    public trafficTrendCardHeader: ICardHeaderConfig = null;
    public channelPerformanceCardHeader: ICardHeaderConfig = null;
    public dealerPerformanceCardHeader: ICardHeaderConfig = null;

}
