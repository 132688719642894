import {
    IDefaultTableColumnConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    IHospitalityWebsiteSummaryConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class HospitalityWebsiteSummaryConfig implements IHospitalityWebsiteSummaryConfig {
    reportTitle = 'Website Summary';
    public useDefaultComparisonColumns = true;
    public metricDisplayModes = [
      { name: 'MOM', value: 'MOM' },
      { name: 'Trend', value: 'Trend' }
  ];
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Website Summary',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.websiteSummaryHelp,
        helpTextTitle: 'Website Summary',
        exportName: 'Website Summary - Export',
        metricDisplayModes: ['MOM']
    };

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
              id: Enums.hospitalityWebsiteMetrics.visits.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.visits.name,
              propertyName: Enums.hospitalityWebsiteMetrics.visits.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.uniqueVisitors.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.uniqueVisitors.name,
              propertyName: Enums.hospitalityWebsiteMetrics.uniqueVisitors.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.clickToCalls.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.clickToCalls.name,
              propertyName: Enums.hospitalityWebsiteMetrics.clickToCalls.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.looks.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.looks.name,
              propertyName: Enums.hospitalityWebsiteMetrics.looks.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.name,
              propertyName: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
              id: Enums.hospitalityWebsiteMetrics.bookingInitiation.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.bookingInitiation.name,
              propertyName: Enums.hospitalityWebsiteMetrics.bookingInitiation.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.name,
              propertyName: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
              id: Enums.hospitalityWebsiteMetrics.bookings.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.bookings.name,
              propertyName: Enums.hospitalityWebsiteMetrics.bookings.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.name,
              propertyName: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
              id: Enums.hospitalityWebsiteMetrics.lookToBookRate.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.lookToBookRate.name,
              propertyName: Enums.hospitalityWebsiteMetrics.lookToBookRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
              id: Enums.hospitalityWebsiteMetrics.roomNights.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.roomNights.name,
              propertyName: Enums.hospitalityWebsiteMetrics.roomNights.nameKey,
              metricFormatKey: constants.formatKeys.localeString
            },
            {
              id: Enums.hospitalityWebsiteMetrics.revenue.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.revenue.name,
              propertyName: Enums.hospitalityWebsiteMetrics.revenue.nameKey,
              metricFormatKey: constants.formatKeys.currencyNoCents
            },
            {
              id: Enums.hospitalityWebsiteMetrics.avgDailyRate.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.avgDailyRate.name,
              propertyName: Enums.hospitalityWebsiteMetrics.avgDailyRate.nameKey,
              metricFormatKey: constants.formatKeys.currencyNoCents
            },
            {
              id: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.name,
              propertyName: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.nameKey,
              metricFormatKey: constants.formatKeys.roundToHundreth
            },
            {
              id: Enums.hospitalityWebsiteMetrics.lengthOfStay.metricId,
              displayName: Enums.hospitalityWebsiteMetrics.lengthOfStay.name,
              propertyName: Enums.hospitalityWebsiteMetrics.lengthOfStay.nameKey,
              metricFormatKey: constants.formatKeys.roundToHundreth
            }
        ],
        defaultMetricOneId: Enums.hospitalityWebsiteMetrics.visits.metricId,
        defaultMetricTwoId: Enums.hospitalityWebsiteMetrics.bookings.metricId,
        metricOneColorHexCode: '#617c6a',
        metricTwoColorHexCode: '#c3982d'
    };
    public trendTableMetricKeys: string[] = [
        Enums.hospitalityWebsiteMetrics.visits.nameKey,
        Enums.hospitalityWebsiteMetrics.uniqueVisitors.nameKey,
        Enums.hospitalityWebsiteMetrics.clickToCalls.nameKey,
        Enums.hospitalityWebsiteMetrics.looks.nameKey,
        Enums.hospitalityWebsiteMetrics.visitorsToLookRate.nameKey,
        Enums.hospitalityWebsiteMetrics.bookingInitiation.nameKey,
        Enums.hospitalityWebsiteMetrics.bookingInitiationRate.nameKey,
        Enums.hospitalityWebsiteMetrics.bookings.nameKey,
        Enums.hospitalityWebsiteMetrics.visitorsToBookRate.nameKey,
        Enums.hospitalityWebsiteMetrics.lookToBookRate.nameKey,
        Enums.hospitalityWebsiteMetrics.roomNights.nameKey,
        Enums.hospitalityWebsiteMetrics.revenue.nameKey,
        Enums.hospitalityWebsiteMetrics.avgDailyRate.nameKey,
        Enums.hospitalityWebsiteMetrics.pageViewPerVisit.nameKey,
        Enums.hospitalityWebsiteMetrics.lengthOfStay.nameKey,
    ];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
      {
        show: true,
        header: '',
        columnDef: 'entity',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
      },
      {
        show: false,
        print: true,
        header: 'Property ',
        columnDef: 'displayName',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      {
        show: true,
        header: 'Visits',
        columnDef: 'visits',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Unique Visitors',
        columnDef: 'uniqueVisitors',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Click to Calls',
        columnDef: 'clickToCalls',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Looks',
        columnDef: 'roomSearches',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Visitor to Look %',
        columnDef: 'roomSearchRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Booking Initiation',
        columnDef: 'bookNowClicks',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Booking Initiation %',
        columnDef: 'bookNowClickRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Bookings',
        columnDef: 'bookingsCompleted',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Visitor to Book %',
        columnDef: 'bookingsCompletedRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Look to Book %',
        columnDef: 'lookToBookRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Room Nights',
        columnDef: 'bookingRoomNights',
        metricFormatKey: constants.formatKeys.localeString,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Revenue',
        columnDef: 'bookedTotalRevenue',
        metricFormatKey: constants.formatKeys.currencyNoCents,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Avg. Daily Rate',
        columnDef: 'revenuePerNight',
        metricFormatKey: constants.formatKeys.currencyNoCents,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Page Views Per Visit',
        columnDef: 'pageViewsPerUniqueVisitor',
        metricFormatKey: constants.formatKeys.roundToHundreth,
        clickable: false,
        hasComparisonMetrics: true
      },
      {
        show: true,
        header: 'Length of Stay',
        columnDef: 'lengthOfStay',
        metricFormatKey: constants.formatKeys.roundToHundreth,
        clickable: false,
        hasComparisonMetrics: true
      }
    ];
}
