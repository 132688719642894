import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';

export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths,
      Enums.dateModes.previousTwelveMonths
    ]
    showOrgCodeInFilter = false;
    showDealerCodeInFilter = false;
    onlyShowDealersInFilter = true;
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Website
        'HospitalityWebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'HospitalityWebsiteOverview',
            filters: ['website-provider', 'device-type', 'channel'],
            defaults: [ 'website-provider', 'device-type' ],
            section: 'website',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        'WebsiteSummary': {
          dateMode: Enums.dateModes.currentMonth,
          reportName: 'WebsiteSummary',
          filters: ['website-provider', 'device-type', 'channel'],
          defaults: [ 'website-provider', 'device-type' ],
          section: 'website',
          orgTypes: [1],
          salesCalendarToggleDisabled: true
      }
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'channel': {
            type: 'channel',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [1],
            selected: [
                { value: 1, display: 'Goodwin' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
    };
}
