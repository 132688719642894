<div *ngIf="layoutHelper.sideNavToggleAction$ | async as toggleStatus">
<ng-container *ngIf="locale$ | async as locale">
  <div class="breadcrumb">
    <div class="pull-left">
      <ng-container *ngIf="pageTitle$ | async as pageTitle">
        <h2>{{pageTitle | translatelayout: locale}}</h2>
      </ng-container>
      <sd-filter-breadcrumbs hidden [filterModel]="filterModel$"></sd-filter-breadcrumbs>
    </div>
    <div class="pull-right">
      <div fxLayout="row">
        <!-- Breadcrumb options -->
        <ng-container *ngIf="showBreadcrumbOptions$ | async">
          <sd-metric-selector *ngIf="showMetricSelector"></sd-metric-selector>
          <layout-sddaterangepicker *ngIf="showDateFilter"></layout-sddaterangepicker>
          <ng-container *ngIf="showBreadcrumbMenu">
            <div style="display: inline-block">
              <button mat-button
                      [matMenuTriggerFor]="printingOptions"
                      style="margin-left: 20px;"
                      class="card-options-button">
                <i class="fa fa-ellipsis-v" style="font-size: 1.4em;"></i>
              </button>
              <mat-menu #printingOptions="matMenu" class="pt-10">
                <button mat-menu-item *ngIf="helpAvailable" (click)="openHelp('StickyFiltersHelp', 'General', locale)">{{'Help' | translate: locale}}</button>
                <button mat-menu-item *ngIf="pdfAvailable" (click)="onClickPDF()">PDF</button>
              </mat-menu>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

</div>
