import {
  ActionReducer,
  MetaReducer,
  on,
  createReducer,
  META_REDUCERS
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as States from './app-state.model';
import { AppActions } from './action-types';
import * as FilterModels from '../_shared/filter/filter.model';
import { LocalStorageService } from '../_shared/services/services-index';
import { storageMetaReducer } from './storage.metareducer';
import { ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY } from './app.tokens';
import { locale } from 'moment';

const _authReducer = createReducer(
  States.initialAuthState,
  on(AppActions.loginSuccess, (state, action) => {
      return {
          ...state,
          auth: action.auth,
          config: action.config
      };
  }),

  on(AppActions.logout, (state, action) => {
      return {
        auth: undefined,
        user: undefined
      };
  }),

  on(AppActions.resetAuth, (state, action) => {
    return {
      auth: undefined,
      user: undefined
    };
  }),

  on(AppActions.initializeCachedUser, (state, action) => {
    return {
      ...state,
      auth: action.user
    };
  }),

);

const _configReducer = createReducer(
  States.initialConfigState,
  on(AppActions.loadConfigSuccess, (state, action) => {
    return {
      ...state,
      ...action
    };
  })
);

const _localeReducer = createReducer(
  States.initialLocaleState,
  on(AppActions.updateLocaleSuccess, (state, action) => {
    return {
      ...state,
      ...action
    };
  }),
  on(AppActions.loadLocaleSuccess, (state, action) => {
    return {
      ...state,
      ...action
    };
  }),
  on(AppActions.updateLocaleSuccess, (state, action) => {
    return {...state, ...action};
  })
);



// export function authReducer(state: States.AppState | undefined, action: Action) {
//   return authReducer(state, action);
// }

export function authReducer(state, action) {
  return _authReducer(state, action);
}

export function configReducer(state, action) {
  return _configReducer(state, action);
}

export function localeReducer(state, action) {
  return _localeReducer(state, action);
}

export function reducers(state, action) // : ActionReducerMap<States.AppState>
{
  return {
    auth: _authReducer,
    config: _configReducer,
    locale: _localeReducer,
    router: routerReducer
  };
}

export function logger(reducer: ActionReducer<any>)
    : ActionReducer<any> {
    return (state, action) => {
        // console.log('state before: ', state);
        // console.log('action', action);

        return reducer(state, action);
    };
}

// factory meta-reducer configuration function
export function getMetaReducers(saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
 ): MetaReducer<any>[] {
return [storageMetaReducer(saveKeys, localStorageKey, storageService)];
}

// export const metaReducers = {
//   provide: META_REDUCERS,
//   deps: [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, LocalStorageService],
//   useFactory: getMetaReducers
// }


// export const metaReducers: MetaReducer<States.AppState>[] =
//     !environment.production ? [logger] : [];

export { States };
