import { Injectable } from '@angular/core';
import { SalesTranslationConfig } from '../../../_config/translation/sales/sales.translation.config';


@Injectable()
export class SalesTranslationService {
  saleTranslationConfig = new SalesTranslationConfig;

  getSaleMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    // passing in and returning default label takes away dependance on any translation file at all
    if (locale === 'en') {
      return defaultLabel;
    }

    return this.saleTranslationConfig.saleMetrics[locale][metricId] ?? '';
  }

  getSaleReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.saleTranslationConfig.saleReportTitles[locale][cleansedValue];
  }

  getSaleLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    // if (value.includes('contr')) {
    //   console.log(value)
    // }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    const label = this.saleTranslationConfig.saleLabels[locale][cleansedValue];
    return label;

  }

  getSaleChartDimension(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.saleTranslationConfig.saleChartDimensions[locale][cleansedValue] || value;
  }

}
