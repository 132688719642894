import { IFilterModel, IOrgLookup } from './../../models/models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import * as FilterModels from '../../filter/filter.model';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Enums, IEnums } from '../../enums/enums';
import { LocalCacheService } from '../cache/localCache.service';

export interface IPrintService {
    getByGuid(guid: string): Observable<Models.IPrintModel>;
    pdfCreated(fileName: string): Promise<string>;
}


@Injectable({
    providedIn: 'root',
})

export class PrintService implements IPrintService {

    private isPrintModeSubject = new BehaviorSubject<boolean>(false);
    isPrintMode$ = this.isPrintModeSubject.asObservable();
    private serviceBase: string = undefined;
    orgLookups: IOrgLookup[];
    filters: IFilterModel;
    private enums: IEnums = Enums;
    constructor(private http: HttpClient, private cache: LocalCacheService) {
        this.serviceBase = environment.baseApiUri;
    }



    getByGuid(guid: string): Observable<Models.IPrintModel> {
        return this.http.post<Models.IPrintModel>(this.serviceBase + '/print/getByGuid?guid=' + guid,
            { headers: { 'Content-Type': 'application/json' } })
            .pipe(map((response: any) => {
                const result: Models.IPrintModel = {
                    path: response.path,
                    reportName: response.reportName,
                    guid: response.guid,
                    printId: response.printId,
                    createDate: response.createDate,
                    expireDate: response.expireDate,
                    locale: response.locale ?? 'en',
                    filterModel: this.mapPrintFilterModel(JSON.parse(response.filterModel)),
                    filters: this.mapPrintFilters(response.path, JSON.parse(response.filters)) // response.filters
                };
                return result;
            }), catchError(this.handleError<Models.IPrintModel>('GetByGuid', null)));
    }

    generatePdf(printRequestModel: Models.PrintReportRequestModel): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.http.post<string>(this.serviceBase + '/print/generatePdf', JSON.stringify(printRequestModel),
            { headers: { 'Content-Type': 'application/json' } })
            .toPromise()
            .then((response: string) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    pdfCreated(fileName: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.http.post<string>(this.serviceBase + '/print/pdfCreated?fileName=' + fileName,
            { headers: { 'Content-Type': 'application/json' } })
            .toPromise()
            .then((response: string) => {
                // console.log('response', response)
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    mapPrintFilterModel(printFilter: any): IFilterModel {
        const filter: IFilterModel = {
            roleLevel: printFilter.RoleLevel,
            roleEntity: '',
            roleId: 1,
            startDate: printFilter.StartDate,
            endDate: printFilter.EndDate,
            previousStartDate: printFilter.PreviousStartDate,
            previousEndDate: printFilter.PreviousEndDate,
            orgCode3: printFilter.OrgCode3,
            orgCode2: printFilter.OrgCode2,
            orgCode1: printFilter.OrgCode1,
            dealerCode: printFilter.DealerCode,
            sourceTypeId: printFilter.SourceTypeId,
            sourceTypes: printFilter.SourceTypes,
            leadDesignations: printFilter.LeadDesignations,
            leadTypeId: printFilter.LeadTypeId,
            leadTypes: printFilter.LeadTypes,
            models: printFilter.Models,
            deviceTypeId: printFilter.DeviceTypeId,
            deviceTypes: printFilter.DeviceTypes,
            orgLookupTypeId: printFilter.OrgLookupTypeId,
            channels: printFilter.Channels,
            websiteProviders: printFilter.WebsiteProviders,
            digAdProviders: printFilter.DigAdProviders,
            chatProviders: printFilter.ChatProviders
        };

        return filter;
    }

    mapPrintFilters(path: string, printfilters: any): FilterModels.Filter[] {

        // console.log('printFilters', printFilters);
        const filters: FilterModels.Filter[] = [];
        const filtersToShow = this.getPrintFiltersToShow(path);
        printfilters.forEach(printFilter => {
            filters.push({
                    type: printFilter.Type,
                    selected: printFilter.Selected,
                    removable: printFilter.Removable,
                    lockable: printFilter.Lockable,
                    locked: printFilter.Locked,
                    visible: filtersToShow.includes(printFilter.Type) ? true : false
                }
            );
        });
        return filters;
    }

    getPrintFiltersToShow(path: string): string[] {
        const filtersToShow = [];

        // always show org filter for overviews (only reports allowing PDF currently)
        filtersToShow.push('org');

        if (path.includes('sales')) {
            filtersToShow.push('lead-type');
        }

        return filtersToShow;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }

    public togglePrintMode(isPrintMode: boolean) {
        this.isPrintModeSubject.next(isPrintMode);
    }
}
