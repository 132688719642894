export const formatKeys = {
    abbreviatedLocaleString: 'abbreviatedLocaleString',
    currency: 'currency',
    currencyNoCents: 'currencyNoCents',
    date: 'date',
    dateTime: 'dateTime',
    dateTimeToMinute: 'dateTimeToMinute',
    decimalToPercentTwoDecimals: 'decimalToPercentTwoDecimals',
    entityDisplayName: 'entityDisplayName',
    entityDisplayNameNormalCase: 'entityDisplayNameNormalCase',
    integerDaysOrNa: 'integerDaysOrNa',
    oneDecimalDaysOrNa: 'onecimalDaysOrNa',
    hoursTimeStringFromMinutes: 'hoursTimeStringFromMinutes',
    hourMinuteString : 'hourminutestring',
    integer: 'integer',
    localeString: 'localeString',
    localeStringOrNa: 'localeStringOrNa',
    minutesTimeString: 'minutesTimeString',
    minutesTimeStringFromSeconds: 'minutesTimeStringFromSeconds',
    percentage: 'percentage',
    percentageNoDecimals: 'percentageNoDecimals',
    percentageOneDecimal: 'percentageOneDecimal',
    percentageTwoDecimals: 'percentageTwoDecimals',
    percentageTwoDecimalsOrNa: 'percentageTwoDecimalsOrNa',
    roundNumber: 'roundNumber',
    roundToHundreth: 'roundToHundredth',
    roundToThousandths: 'roundToThousandths',
    roundToInteger: 'roundToInteger',
    roundToIntegerLocaleString: 'roundToIntegerLocaleString',
    roundToTenth: 'roundToTenth',
    stringOrNa: 'stringOrNa'
};

export const helpTextKeys = {
    // Lead Overview
    leadsOverviewLeadVolumeBillboardHelp: 'LeadsOverviewLeadVolumeBillboardHelp',
    leadsOverviewResponded60BillboardHelp: 'LeadsOverviewResponded60BillboardHelp',
    leadsOverviewAverageResponseTimeBillboardHelp: 'LeadsOverviewAverageResponseTimeBillboardHelp',
    leadsOverviewCloseRateBillboardHelp: 'LeadsOverviewCloseRateBillboardHelp',
    leadsOverviewRespondseRate30BillboardHelp: 'LeadsOverviewAverageResponseRateRateBillboardHelp',
    // Provider Tools
    // Chat
    chatSummaryHelp: 'ChatSummaryHelp',
    // Reputation Management
    reputationManagementOverviewSummaryHelp: 'ReputationManagementDealerSummaryHelp',
    reputationManagementOverviewAverageRatingBillboardHelp: 'ReputationManagementOverviewAverageRatingBillboardHelp',
    reputationManagementOverviewTotalPositiveReviewsBillboardHelp: 'ReputationManagementOverviewTotalPositiveReviewsBillboardHelp',
    reputationManagementOverviewTotalNegativeReviewsBillboardHelp: 'ReputationManagementOverviewTotalNegativeReviewsBillboardHelp',
    reputationManagementOverviewTotalReviewsBillboardHelp: 'ReputationManagementOverviewTotalReviewsBillboardHelp',
    reputationManagementOverviewTotalDealerResponsesBillboardHelp: 'ReputationManagementOverviewTotalDealerResponsesBillboardHelp',
    // Social Media
    socialMediaOverviewSummaryHelp: 'SocialMediaDealerSummaryHelp',
    socialMediaOverviewTotalFansFollowersBillboardHelp: 'SocialMediaOverviewTotalFansFollowersBillboardHelp',
    socialMediaOverviewTotalPostsBillboardHelp: 'SocialMediaOverviewTotalPostsBillboardHelp',
    socialMediaOverviewTotalReachBillboardHelp: 'SocialMediaOverviewTotalReachBillboardHelp',
    socialMediaOverviewTotalEngagementBillboardHelp: 'SocialMediaOverviewTotalEngagementBillboardHelp',
    socialMediaOverviewTotalAdvocacyBillboardHelp: 'SocialMediaOverviewTotalAdvocacyBillboardHelp',


    // website overview billboard
    websiteOverviewActionsBillboardHelp: 'WebsiteOverviewActionsBillboardHelp',
    websiteOverviewEngagementsBillboardHelp: 'WebsiteOverviewEngagementsBillboardHelp',
    websiteOverviewUniqueVisitsBillboardHelp: 'WebsiteOverviewUniqueVisitsBillboardHelp',
    websiteOverviewClickToCallsBillboardHelp: 'WebsiteOverviewClickToCallsBillboardHelp',
    websiteOverviewNewVDPViewsBillboardHelp: 'WebsiteOverviewNewVDPViewsBillboardHelp',
    websiteOverviewCPOVDPViewsBillboardHelp: 'WebsiteOverviewCPOVDPViewsBillboardHelp',
    websiteOverviewVDPViewsBillboardHelp: 'WebsiteOverviewVDPViewsBillboardHelp',
    websiteOverviewVisitsBillboardHelp: 'WebsiteOverviewVisitsBillboardHelp',
    websiteOverviewBookingsCompletedBillboardHelp: 'WebsiteOverviewBookingsBillboardHelp',
    websiteOverviewBookedRoomRevenueBillboardHelp: 'WebsiteOverviewRevenueBillboardHelp',
    // website overview cards
    websiteOverviewBookingWindowHelp: 'websiteOverviewBookingWindowHelp',
    websiteOverviewBenchmarkHelp: 'WebsiteOverviewBenchmarkHelp',
    websiteOverviewReferrerQualityHelp: 'WebsiteOverviewReferrerQualityHelp',
    websiteOverviewReferrerTypeHelp: 'WebsiteOverviewReferrerTypeHelp',
    websiteOverviewTrafficByDeviceTypeHelp: 'WebsiteOverviewTrafficByDeviceHelp',
    websiteOverviewVisitorTrendHelp: 'WebsiteOverviewVisitorTrendHelp',
    // website reports
    WebsiteProviderSummaryHelp: 'WebsiteProviderSummaryHelp',
    WebsiteProviderScorecardHelp: 'WebsiteProviderScorecardHelp',
    websiteReferrerSummaryHelp: 'WebsiteReferrerSummaryHelp',
    websiteSummaryHelp: 'WebsiteSummaryHelp',
    websiteVdpSummaryHelp: 'WebsiteVdpSummaryHelp',
    // digad reports
    digAdSummaryHelp: 'DigAdSummaryHelp',
    digAdProviderSummaryHelp: 'DigAdProviderSummaryHelp',
    digAdChannelSummaryHelp: 'DigAdChannelSummaryHelp',
    // digad Overview Cards
    digAdOverviewProviderPerformanceHelp: 'DigAdOverviewProviderPerformanceHelp',
    digAdOverviewDealerPerformanceHelp: 'DigAdOverviewBenchmarkHelp',
    digAdOverviewTrafficTrendHelp: 'DigAdOverviewTrafficTrendHelp',
    digAdOverviewChannelPerformanceHelp: 'DigAdOverviewChannelHelp',
    digAdOverviewTrafficTypeHelp: 'DigAdOverviewOrganicVsPaidHelp',
};

export const orgDateCodes = {
  calendar: 1,
  sales: 2
};

export const orgHierarchyDepth = {
  national: 4,
  org3: 3,
  org2: 2,
  org1: 1,
  dealer: 0
}
