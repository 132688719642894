import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Event, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable()
export class SpinnerService {
	spinnerActivated = new EventEmitter<boolean>(false);
	isSpinnerVisible = false;

	constructor(private router: Router) {
		this.router.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
	}

	show() {
		this.isSpinnerVisible = true;
		this.spinnerActivated.emit(true);
	}

	hide() {
    // console.log('hiding spinner...');
		this.isSpinnerVisible = false;
		this.spinnerActivated.emit(false);
	}

	private onRouteEvent(routeEvent: Event) {
		// if (routeEvent instanceof NavigationStart)
		//  this.show();

		// if (routeEvent instanceof NavigationEnd)
		//  this.hide();
	}
}
