

import {
  IWebsiteOverviewBillboardConfig,
  IHospitalityWebsiteOverviewConfig,
  IDefaultTableColumnConfig,
  IWebsiteOverviewKpiConfig,
  ICardHeaderConfig,
  IAnalysisVisualizationHeaderConfig,
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class HospitalityWebsiteOverviewConfig implements IHospitalityWebsiteOverviewConfig {
  public reportTitle = 'Website Overview';
  public benchmarkTitle = 'Properties';
  public useNewMetricCutoffDate = true;
  public useDefaultComparisonColumns = true;
  public newMetricCutoffDate = new Date(2019, 0, 1);
  public chartSeriesColors = ['#458691', '#c3982d', '#617c6a', '#042f36', '#314c39', '#c1c5b7', '#e2cba5', '#262624', '#908e90', '#7c2fl8'];
  public deviceTypeColors = ['#000', '#847450', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public referrerTypeColors = ['#847450', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'];
  public referrerQualityColors = ['#847450', '#224197', '#f9bb11', '#c51230'];
  public showPieChart = true;
  public calendarColors = ['#f2f2f2', '#617c6a'];
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' }
];
  public referrerQualityCardHeader: ICardHeaderConfig = {
    title: 'Referrer Quality',
    iconClass: 'fa-star',
    helpTextKey: constants.helpTextKeys.websiteOverviewReferrerQualityHelp,
    helpTextTitle: 'Referrer Quality',
  };
  public analysisVisualizationHeaderConfig: IAnalysisVisualizationHeaderConfig = {
    visualizationOptions: {
      defaultPerspective: 'channel',
      perspectives: [
        {
          code: 'channel',
          displayName: 'Channel',
          iconClass: 'fa-pie-chart',
          helpConfig: {
            enabled: true,
            helpTextKey: constants.helpTextKeys.websiteOverviewReferrerTypeHelp,
            helpTextTitle: 'Channel'
          },
          expando: {
            enabled: true
          },
          metricSelect: {
            enabled: true,
            //selectedMetricId: Enums.hospitalityWebsiteMetrics.bookings.metricId,
            metricSelectConfig: {
              defaultMetricId: Enums.hospitalityWebsiteMetrics.bookings.metricId,
              options: [
                {
                  id: Enums.hospitalityWebsiteMetrics.visits.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.visits.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.visits.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.uniqueVisitors.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.uniqueVisitors.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.uniqueVisitors.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.clickToCalls.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.clickToCalls.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.clickToCalls.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.looks.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.looks.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.looks.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.nameKey,
                  metricFormatKey: constants.formatKeys.percentageTwoDecimals
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.bookingInitiation.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.bookingInitiation.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.bookingInitiation.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.nameKey,
                  metricFormatKey: constants.formatKeys.percentageTwoDecimals
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.bookings.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.bookings.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.bookings.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.nameKey,
                  metricFormatKey: constants.formatKeys.percentageTwoDecimals
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.lookToBookRate.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.lookToBookRate.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.lookToBookRate.nameKey,
                  metricFormatKey: constants.formatKeys.percentageTwoDecimals
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.roomNights.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.roomNights.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.roomNights.nameKey,
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.revenue.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.revenue.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.revenue.nameKey,
                  metricFormatKey: constants.formatKeys.currencyNoCents
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.avgDailyRate.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.avgDailyRate.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.avgDailyRate.nameKey,
                  metricFormatKey: constants.formatKeys.currencyNoCents
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.nameKey,
                  metricFormatKey: constants.formatKeys.roundToHundreth
                },
                {
                  id: Enums.hospitalityWebsiteMetrics.lengthOfStay.metricId,
                  displayName: Enums.hospitalityWebsiteMetrics.lengthOfStay.name,
                  propertyName: Enums.hospitalityWebsiteMetrics.lengthOfStay.nameKey,
                  metricFormatKey: constants.formatKeys.roundToHundreth
                }
              ]
            }
          },
          dataSliceConfig: {
            enabled: true,
            sliceOptions: [
              { name: 'Organic Search', value: 'ORGANICSEARCH' },
              { name: 'Group Sites', value: 'GROUPSITES' },
              { name: 'Paid Search', value: 'PAIDSEARCH' },
              { name: 'Typed/Bookmarked', value: 'TYPEDBOOKMARKED' },
              { name: 'Travel Sites', value: 'TRAVELSITES' },
              { name: 'Paid Social', value: 'PAIDSOCIAL' },
              { name: 'Paid Display', value: 'PAIDDISPLAY' },
              { name: 'Organic Social', value: 'ORGANICSOCIAL' },
              { name: 'Paid Video', value: 'PAIDVIDEO' },
              { name: 'Paid Email', value: 'PAIDEMAIL' },
              { name: 'Organic Email', value: 'ORGANICEMAIL' },
              { name: 'Other', value: 'OTHER' },
              { name: 'CoralTree Traffic', value: 'BRAND' }
            ]
          }
        },
        {
          code: 'bookingWindow12Months',
          displayName: 'Booking Window (12-Months)',
          iconClass: 'fa-calendar-check-o fa-solid',
          helpConfig: {
            enabled: true,
            helpTextKey: constants.helpTextKeys.websiteOverviewBookingWindowHelp,
            helpTextTitle: 'Booking Window'
          },
          expando: {
            enabled: true
          },
          metricSelect: {
            enabled: false,
            //selectedMetricId: null,
            metricSelectConfig: null
          },
          dataSliceConfig: {
            enabled: true,
            sliceOptions: [
              { name: 'Same Day', value: 'sameDayBookings' },
              { name: '1-7 Days', value: 'sevenDayBookings' },
              { name: '8-15 Days', value: 'fifteenDayBookings' },
              { name: '16-30 Days', value: 'thirtyDayBookings' },
              { name: '31-60 Days', value: 'sixtyDayBookings' },
              { name: '61-90 Days', value: 'ninetyDayBookings' },
              { name: '91+ Days', value: 'ninetyOnePlusDayBookings' }
            ]
          }
        },
        {
          code: 'bookingPace12Months',
          displayName: 'Booking Pace (12-Months)',
          iconClass: 'fa-calendar-check-o fa-solid',
          helpConfig: {
            enabled: true,
            helpTextKey: 'WebsiteOverviewBookingPaceHelp',
            helpTextTitle: 'Booking Pace'
          },
          expando: {
            enabled: true
          },
          metricSelect: {
            enabled: true,
            metricSelectConfig: {
              defaultMetricId: 1,
              options: [
                {
                  id: 1,
                  displayName: 'Bookings',
                  propertyName: 'totalBookingsCompleted',
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: 2,
                  displayName: 'Room Nights',
                  propertyName: 'totalBookedRooms',
                  metricFormatKey: constants.formatKeys.localeString
                },
                {
                  id: 3,
                  displayName: 'Revenue',
                  propertyName: 'transactionTotal',
                  metricFormatKey: constants.formatKeys.currency
                }
              ]
            }
          },
          dataSliceConfig: {
            enabled: false,
            sliceOptions: null
          }
        }
      ]
    }
  }

  public trafficByDeviceTypeHeader: ICardHeaderConfig = {
    title: 'Traffic By Device',
    iconClass: 'fa-mobile',
    helpTextKey: constants.helpTextKeys.websiteOverviewTrafficByDeviceTypeHelp,
    helpTextTitle: 'Traffic By Device',
  };
  public visitorTrendCardHeader: ICardHeaderConfig = {
    title: 'Trend',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.websiteOverviewVisitorTrendHelp,
    helpTextTitle: 'Trend',
    availableMetrics: [
      {
        id: Enums.hospitalityWebsiteMetrics.visits.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.visits.name,
        propertyName: Enums.hospitalityWebsiteMetrics.visits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },{
        id: Enums.hospitalityWebsiteMetrics.uniqueVisitors.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.uniqueVisitors.name,
        propertyName: Enums.hospitalityWebsiteMetrics.uniqueVisitors.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.hospitalityWebsiteMetrics.clickToCalls.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.clickToCalls.name,
        propertyName: Enums.hospitalityWebsiteMetrics.clickToCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.hospitalityWebsiteMetrics.looks.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.looks.name,
        propertyName: Enums.hospitalityWebsiteMetrics.looks.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.name,
        propertyName: Enums.hospitalityWebsiteMetrics.visitorsToLookRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.hospitalityWebsiteMetrics.bookingInitiation.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.bookingInitiation.name,
        propertyName: Enums.hospitalityWebsiteMetrics.bookingInitiation.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.name,
        propertyName: Enums.hospitalityWebsiteMetrics.bookingInitiationRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.hospitalityWebsiteMetrics.bookings.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.bookings.name,
        propertyName: Enums.hospitalityWebsiteMetrics.bookings.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.name,
        propertyName: Enums.hospitalityWebsiteMetrics.visitorsToBookRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.hospitalityWebsiteMetrics.lookToBookRate.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.lookToBookRate.name,
        propertyName: Enums.hospitalityWebsiteMetrics.lookToBookRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.hospitalityWebsiteMetrics.roomNights.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.roomNights.name,
        propertyName: Enums.hospitalityWebsiteMetrics.roomNights.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.hospitalityWebsiteMetrics.revenue.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.revenue.name,
        propertyName: Enums.hospitalityWebsiteMetrics.revenue.nameKey,
        metricFormatKey: constants.formatKeys.currencyNoCents
      },
      {
        id: Enums.hospitalityWebsiteMetrics.avgDailyRate.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.avgDailyRate.name,
        propertyName: Enums.hospitalityWebsiteMetrics.avgDailyRate.nameKey,
        metricFormatKey: constants.formatKeys.currencyNoCents
      },
      {
        id: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.name,
        propertyName: Enums.hospitalityWebsiteMetrics.pageViewPerVisit.nameKey,
        metricFormatKey: constants.formatKeys.roundToHundreth
      },
      {
        id: Enums.hospitalityWebsiteMetrics.lengthOfStay.metricId,
        displayName: Enums.hospitalityWebsiteMetrics.lengthOfStay.name,
        propertyName: Enums.hospitalityWebsiteMetrics.lengthOfStay.nameKey,
        metricFormatKey: constants.formatKeys.roundToHundreth
      }
    ],
    defaultMetricId: Enums.hospitalityWebsiteMetrics.bookings.metricId,
    codeSlicers: [
      {name: 'Organic Search', value: 'ORGANICSEARCH'},
      {name: 'Group Sites', value: 'GROUPSITES'},
      {name: 'Paid Search', value: 'PAIDSEARCH'},
      {name: 'Typed/Bookmarked', value: 'TYPEDBOOKMARKED'},
      {name: 'Travel Sites', value: 'TRAVELSITES'},
      {name: 'Paid Social', value: 'PAIDSOCIAL'},
      {name: 'Paid Display', value: 'PAIDDISPLAY'},
      {name: 'Organic Social', value: 'ORGANICSOCIAL'},
      {name: 'Paid Video', value: 'PAIDVIDEO'},
      {name: 'Paid Email', value: 'PAIDEMAIL'},
      {name: 'Organic Email', value: 'ORGANICEMAIL'},
      {name: 'Other', value: 'OTHER'},
      {name: 'CoralTree Traffic', value: 'BRAND'},
    ]
  };;
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Dealer Performance',
    iconClass: 'fa-suitcase fa-icon',
    helpTextKey: constants.helpTextKeys.websiteOverviewBenchmarkHelp,
    helpTextTitle: 'Benchmark',
    exportName: 'Website Overview - Dealer Performance',
    metricDisplayModes: ['MOM']
  };
  public billboards: IWebsiteOverviewBillboardConfig[] = [
    {
      title: 'Visits',
      subtitle: null,
      iconClass: 'fa-users fa-icon',
      reverseMetric: false,
      metricCurrentPropertyName: 'visits',
      metricMOMPropertyName: 'visitsMOM',
      metricYOYPropertyName: 'visitsYOY',
      metricPreviousMonthPropertyName: 'visitsPreviousMonth',
      metricPreviousMTDPropertyName: 'visitsPreviousMTD',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'visitsMOM',
      metricFormatKey: constants.formatKeys.localeString,
      helpTextKey: constants.helpTextKeys.websiteOverviewVisitsBillboardHelp,
      helpTextTitle: 'Visits',
      showTrendArrow: true
    },
    {
      title: 'Click to Calls',
      subtitle: null,
      iconClass: 'fa-phone fa-icon',
      reverseMetric: false,
      metricCurrentPropertyName: 'clickToCalls',
      metricMOMPropertyName: 'clickToCallsMOM',
      metricYOYPropertyName: 'clickToCallsYOY',
      metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
      metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'clickToCallsMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: constants.helpTextKeys.websiteOverviewClickToCallsBillboardHelp,
      helpTextTitle: 'Click To Calls',
      showTrendArrow: true
    },
    {
      title: 'Bookings',
      subtitle: null,
      iconClass: 'fa-calendar-check-o fa-icon',
      reverseMetric: false,
      metricCurrentPropertyName: 'bookingsCompleted',
      metricMOMPropertyName: 'bookingsCompletedMOM',
      metricYOYPropertyName: 'bookingsCompletedYOY',
      metricPreviousMonthPropertyName: 'bookingsCompletedPreviousMonth',
      metricPreviousMTDPropertyName: 'bookingsCompletedPreviousMTD',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'bookingsCompletedMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: constants.helpTextKeys.websiteOverviewBookingsCompletedBillboardHelp,
      helpTextTitle: 'Bookings',
      showTrendArrow: true
    },
    {
      title: 'Revenue',
      subtitle: null,
      iconClass: 'fa-usd fa-icon',
      reverseMetric: false,
      metricCurrentPropertyName: 'bookedTotalRevenue',
      metricMOMPropertyName: 'bookedTotalRevenueMOM',
      metricYOYPropertyName: 'bookedTotalRevenueYOY',
      metricPreviousMonthPropertyName: 'bookedTotalRevenuePreviousMonth',
      metricPreviousMTDPropertyName: 'bookedTotalRevenuePreviousMTD',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'bookedTotalRevenueMOM',
      metricFormatKey: constants.formatKeys.currencyNoCents,
      helpTextKey: constants.helpTextKeys.websiteOverviewBookedRoomRevenueBillboardHelp,
      helpTextTitle: 'Revenue',
      showTrendArrow: true
    },
  ];
  public kpiCardConfig: IWebsiteOverviewKpiConfig = {
    chartTitle: 'Visits & Bookings',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
        yAxisTitle: 'Visits',
        displayName: 'Visits',
        pointLabelFormat: null,
        propertyName: 'visits',
        metricFormatKey: null,
        formatter: null
      },
      'MetricTwo': {
        yAxisTitle: 'Bookings',
        displayName: 'Bookings',
        pointLabelFormat: null,
        propertyName: 'bookingsCompleted',
        metricFormatKey: null,
        formatter: null
      }
    }
  };
  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Property ',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    {
      show: true,
      header: 'Visits',
      columnDef: 'visits',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Unique Visitors',
      columnDef: 'uniqueVisitors',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Click to Calls',
      columnDef: 'clickToCalls',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Looks',
      columnDef: 'roomSearches',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Visitor to Look %',
      columnDef: 'roomSearchRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Booking Initiation',
      columnDef: 'bookNowClicks',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Booking Initiation %',
      columnDef: 'bookNowClickRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Bookings',
      columnDef: 'bookingsCompleted',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Visitor to Book %',
      columnDef: 'bookingsCompletedRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Look to Book %',
      columnDef: 'lookToBookRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Room Nights',
      columnDef: 'bookingRoomNights',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Revenue',
      columnDef: 'bookedTotalRevenue',
      metricFormatKey: constants.formatKeys.currencyNoCents,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Avg. Daily Rate',
      columnDef: 'revenuePerNight',
      metricFormatKey: constants.formatKeys.currencyNoCents,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Page Views Per Visit',
      columnDef: 'pageViewsPerUniqueVisitor',
      metricFormatKey: constants.formatKeys.roundToHundreth,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Length of Stay',
      columnDef: 'lengthOfStay',
      metricFormatKey: constants.formatKeys.roundToHundreth,
      clickable: false,
      hasComparisonMetrics: true
    },
  ];
}
