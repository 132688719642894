import {
    IDefaultTableColumnConfig,
    IDigAdSummaryConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';

export class DigAdSummaryConfig implements IDigAdSummaryConfig {
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IDualAxisLineGraphConfig = null;
    public trendTableMetricKeys: string[] = [];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
