import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

  //TO DO - will 
    orgLookupTypes: Record<string, IOrgLookupType> = {
        'national': { name: 'All Properties', orgLookupTypeId: 1, default: true, orgEntityType: 'national' },
    };

    public nationalOrgLookupTypeIds: number[] = [1];
    public lmaOrgLookupTypeIds: number[] = [2];
    public virtual20OrgLookupTypeIds: number[] = [3];
    public dealerGroupOrgLookupTypeIds: number[] = [4];

    orgEntityType: Map<string, string> = new Map([
        ['dealer', 'dealer'],
    ]);

    entityTypeName: Map<string, string> = new Map([
        ['dealer', 'Property'],
    ]);
}
